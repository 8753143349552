<template>
  <div class="app-container">
    <div class="container">
        <nav>
            <div></div>
            <div class="tips">
                <el-button type="primary" class="btn" @click="addDiscount" v-if="category=='1'" :disabled="showLoading" >新增折扣</el-button>
                <el-button plain class="btn able" @click="submitForm('employForm')" v-if="category=='1'"  :loading='isLoading' >开具发票</el-button>
                <el-button plain class="btn able" @click="redFlush('employForm')" v-if="category=='2'"  :loading='isLoading' >红冲</el-button>
            </div>
        </nav>
        <p class="date"> <span>开票日期: {{employData.kprq}}</span> <span v-if="category=='2'">原发票号码: {{employData.fphm}}</span> <span v-if="category=='2'">原发票代码: {{employData.fpdm}}</span></p>
        <el-form ref="employForm" :rules="rules" :model="employData" label-width="7rem" class="employ_form" v-loading="showLoading">
            <div class="title">
                {{title}}
            </div>
            <div class="direction">
                <div class="header">
                    购 买 方
                </div>
                <div class="box1">
                    <el-form-item class="before" label='名称' prop='ghdwmc' :rules="[{ required: true, message: '请输入名称', trigger: 'blur',},
                    ]" label-position="left" >
                        <el-autocomplete v-model="employData.ghdwmc" :fetch-suggestions="querySearchAsync" placeholder="请输入名称"
                         @select="handleSelect" :maxlength="50" :disabled="category=='2'"></el-autocomplete>
                    </el-form-item>
                      <!-- [{ required: true, message: '请输入纳税人识别号:', trigger: 'blur',},{ pattern: '^[A-Z0-9]{18}$', message: '纳税人识别号:不合法', trigger: 'blur' },] :  -->
                    <el-form-item :class="typeId=='02'?'before':''" label='纳税人识别号' prop="ghdwsbh" :rules="typeId == '02' ?
                      [{ required: true, validator: checkTax, trigger: 'blur',},]:
                      [{ validator: checkTax1, trigger: 'blur',}]
                    ">
                        <el-input v-model='employData.ghdwsbh' maxlength="20" placeholder="请输入纳税人识别号" :disabled="category=='2'"/>
                    </el-form-item>
                    <el-form-item :class="typeId=='02'?'before':''" label='地址、电话' prop="ghdwdzdh" :rules="typeId == '02' ?[{ required: true, message: '请输入地址及电话', trigger: 'blur',}] : []">
                        <el-input v-model='employData.ghdwdzdh' maxlength="100" placeholder="请输入地址及电话"  :disabled="category=='2'"/>
                    </el-form-item>
                    <el-form-item :class="typeId=='02'?'before':''" label='开户行及账号' prop="ghdwyhzh" :rules="typeId == '02' ?[{ required: true, message: '请输入开户行及账号', trigger: 'blur',}] : []">
                        <el-input v-model='employData.ghdwyhzh' maxlength="50" placeholder="请输入开户行及账号"  :disabled="category=='2'"/>
                    </el-form-item>
                </div>
                <div class="box2">
                    <el-form-item class="before" label='收票人电子邮箱' prop='email' label-position="left">
                        <el-input v-model='employData.email' placeholder="请输入收票人电子邮箱"  />
                    </el-form-item>
                    <el-form-item label='收票人手机号' prop='phone' label-position="left">
                        <el-input v-model='employData.phone' placeholder="请输入收票人手机号"  />
                    </el-form-item>
                </div>  
            </div>
            <employTable ref="employRefs" v-model="employData" :typeId='typeId' :category='category' v-loading="insurLoading" @changeSl='changeSslkjly'/>

            <div class="direction direction1">
                <div class="header">
                    销 售 方
                </div>
                <div class="box1 box4">
                    <p label='名称' prop='name' label-position="left" >
                        {{employData.xhdwmc}}
                    </p>
                    <p label='纳税人识别号' prop="creditCode" >
                        {{employData.xhdwsbh}}
                    </p>
                    <p label='地址、电话' prop="addressPhone" >
                        {{employData.xhdwdzdh}}
                    </p>
                    <p label='开户行及账号' prop="accountBankNo" >
                        {{employData.xhdwyhzh}}
                    </p>
                </div>
                <div class="header">
                    备 注
                </div>
                <div class="box3">
                  <!-- :autosize="{ minRows: 5.5, maxRows: 5.5}" -->
                    <el-input type="textarea"  v-model="employData.bz"  :disabled="category=='2'" placeholder="请输入备注"></el-input>
                    <el-form-item v-if="category=='1'" :class=" issueStatus ? 'before':'' " class="sslkjly" label="3%税率开具理由" prop='sslkjly' label-width="7rem" :rules=" issueStatus ?[{ required: true, message: '请选择开具理由', trigger: 'blur',}] : []">
                      <el-select v-model="employData.sslkjly" placeholder="请选择开具理由" clearable class="settingSelect" >
                        <el-option v-for="item in issueList" :key="item.value" :label="item.name" :value="item.value"/>
                      </el-select>
                    </el-form-item>
                </div>  
            </div>
            <el-row class="footer" v-if="category=='2'">
              <el-col :span="6" :offset="0" v-if="typeId=='01'">
                  <el-form-item class="before" label="红冲原因" prop='chyy' label-width="6rem" >
                      <el-select v-model="employData.chyy" placeholder="请选择红冲原因" clearable class="settingSelect">
                        <el-option v-for="item in reasonList" :key="item.value" :label="item.name" :value="item.value"/>
                      </el-select>
                  </el-form-item>
              </el-col>
              <el-col :span="6" :offset="0" v-if="typeId=='02'">
                <el-form-item class="before" label="红字通知单编号" prop='tzdbh' label-width="6rem" >
                  <el-input v-model="employData.tzdbh" :maxlength="20" placeholder="请输入红字通知单编号" class="proportionInput" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" :offset="0" v-if="category=='2'">
                <el-form-item :class=" issueStatus ? 'before':'' " label="3%税率开具理由" prop='sslkjly' label-width="7rem" :rules=" issueStatus ?[{ required: true, message: '请选择开具理由', trigger: 'blur',}] : []">
                  <el-select v-model="employData.sslkjly" placeholder="请选择开具理由" filterable class="settingSelect" >
                    <el-option v-for="item in issueList" :key="item.value" :label="item.name" :value="item.value"/>
                  </el-select>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row class="footer">
                <el-col :span="6" :offset="0" >
                    <el-form-item label="收款人"  label-width="3rem" > <!-- prop='taxExcludedAmount' -->
                            <el-input  v-model="employData.skr" placeholder="请输入收款人"  :disabled="category=='2'" class="proportionInput" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="0" > 
                      <el-form-item label="复核人"  label-width="3rem">   <!-- prop='taxRate' -->
                            <el-input  v-model="employData.fhr" placeholder="请输入复核人"  :disabled="category=='2'" class="proportionInput" ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="0" >
                        <el-form-item label="开票人" label-width="3rem" >
                            <el-input  v-model="employData.kpr"  placeholder="请输入开票人"  :disabled="category=='2'"  class="proportionInput"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" :offset="0" >
                        <el-form-item label="销售方:(章)" label-width="5rem" >
                            <!-- <el-input v-model="employData.amountTotal" class="proportion" disabled></el-input> -->
                        </el-form-item>
                    </el-col>
            </el-row>
          </el-form>
        </div>

        <msg icon="iconfont iconicon1-17" color='#35C4B1' :message="message" :promptVisible="promptVisible" :column="true" :row="false" top="22vh" class="employ_msg" :showClose="true" />

        <!-- 亲，您已录入发票数据，确定要清除，重新录入发票明细吗？ -->
  </div>
</template>

<script>
/**
 * 发票 新增发票 表单组件
 * @author zs
 * @since 2021-8-25
 */
  import employTable from './employTable.vue'
  import saveDialog from '@/components/Dialog'
  import VMoneyFormat from '@/directive/moneyFormat';
  import axios from 'axios';
  import moment from 'moment'        
  import { regionData ,CodeToText } from 'element-china-area-data'
//   import { accAccountCompany } from '@/api/accountSets'  //客户关联社会信用代码
  import { plusOrMinus , decimalPoint} from '@/utils'
  const uuid = require('uuid');
  
  export default {
    props: {
      title: {    // 新增发票类别
        type: String,
      },
      type:{
        type: String,
      },
      typeId :{  // 01 普票 02 专票
        type: String,
      },
      category:{ // 1 正票 2 负票
        type: String,
      },
      reopen:{ // 重开
        type: String,
      },
      config: {
        type: Object,
        default: () => {
          return { precision: 2, thousands: ',' }
        }
      }
    },
    directives: { MoneyFormat: VMoneyFormat },
    components: { employTable, },
    data() {
      return {
        showLoading: false,
        isLoading: false,
        isSaving: false,
        insurLoading: false,
        saveDialog: false,
        promptVisible: false,
        message: '',
        
        issueStatus : false ,

        employData: {
          companyId: this.getToken('companyId'),   //开票公司ID	
          fplxdm: this.typeId == '01' ? '026' : '028' , //发票类型代码 “004”增值税专用发票；“007”增值税普通发票；“026”增值税电子普通发票；“028”增值税电子专用发票
          kplx: this.category=='2'?'1':'0',           // 开票类型 0：正数票开具；1：负数票开具		
          userId:this.getToken('userId'),  //操作人Id	
          invoicePointId:'', // 开票点ID	
          fpqqlsh: uuid.v1(),       // 发票请求流水号	
          hjje:'',           // 合计金额（不含税）		
          bhshjje:'',        // 不含税合计金额		
          hjse:'',           // 合计税额	
          jshj :'',          // 价税合计	
          bz:'',              //备注
          
          ghdwmc:'',         // 购货 单位名称
          ghdwdzdh:'',        //购货 单位地址电话	
          ghdwsbh:'',         //购货 单位识别号	
          ghdwyhzh:'',        //购货 单位银行帐号	
          email:'',           //邮箱
          phone:'',           //手机号	

          // kpr:'',             //开票人	
          kpr:this.getToken('phone'),             //开票人	
          fhr :'',            //复核人	
          skr :'',            //收款人	
          
          xhdwmc: '',         //销货 单位名称
          xhdwsbh:'',         //销货 单位识别号		
          xhdwdzdh:'',        //销货 单位地址电话
          xhdwyhzh:'',        //销货 单位银行帐号	

          fpdm:'',           //原发票代码	
          fphm:'',           //原发票号码	
          kprq:moment(new Date()).format("YYYY-MM-DD"),           //原开票日期	

          chyy:'',  //红冲原因
          tzdbh:'', //通知单编号

          sslkjly:'', //3%税率开具理由

          fyxm: [
            {
              id: 1,
              fphxz:'0',    //发票行性质 0 正常行;1 折扣行;2 被折扣行
              hsbz:'',     //含税标志， 0 不含税；1 含税
              se:'',       //税额		
              sl:'',       //税率
              spbm:'',     //商品编码			
              spmc:'',     //商品名称	
              xh:'',			 //行号
              dw:'',       //单位	
              ggxh:'',     //规格型号	

              spsl:undefined,     //商品数量
              dj:undefined,       //单价
              bhsdj:undefined,    //不含税单价
              je:undefined,       //金额	
              bhsje:undefined,    //不含税金额

              zxbm:'',     //纳税人自行编码	

              lslbs:'',
              yhzcbs:'',
              zzstsgl:'',

              discount : false,
              haveDiscount : false,
              disabled:false,
              relationId: '', // 折扣行关联ID
              // id: 1,
              // price:'',         //单价
              // amount : '',      //金额
              // taxAndAmount:'',
              // model : "",      //规格型号
              // num : '',         //数量
              // taxAmount : undefined,   //税额
              // taxRate : '',    //税率
              // taxAmountCount : '',     //金额计算
              // tradeName : "",  //商品名称
              // unit : "" , //单位})
            },
          ]
        },

        rules: {
          billingDate: [
            { required: true, message: '请选择开票日期', trigger: 'blur' }
          ],
          invoiceCode: [
            { required: true, message: '请输入发票代码', trigger: 'blur' },
            { pattern: '^[0-9]{10}$|[0-9]{12}$', message: '发票代码格式不正确', trigger: 'blur' }
          ],
          invoiceNo: [
            { required: true, message: '请输入发票号码', trigger: 'blur' },
            { pattern: '^[0-9]{8}$', message: '发票号码格式不正确', trigger: 'blur' }
          ],
          phone: [
            { message: '请输入手机号', trigger: 'blur' },
            { pattern: '^1[0-9][0-9]{9}$', message: '请输入正确的手机号', trigger: 'blur' }
          ],
          email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
            { pattern: '^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$', message: '请输入正确的邮箱', trigger: 'blur' }
          ],
          taxExcludedAmount:[
            { required: true, message: '请输入不含税金额', trigger: 'blur',}
          ],
          taxAmount:[
            { required: true, message: '请输入税额', trigger: 'blur',}
          ],
          checkCode:[{ pattern: '^[a-z0-9A-Z]{20}$', message: '校验码格式不正确', trigger: 'blur' }],

          chyy:[{ required: true, message: '请选择红冲原因', trigger: 'blur',}],
          tzdbh:[{ required: true, message: '请输入红字通知单编号', trigger: 'blur',}],
        },
        templateOptions:[{value: '1', name: '销售收入' },],
        issueList:[
          {value: '2', name: '前期已开具发票，发生销售折让、中止或者退回等情形需要开具红字发票，或者开票有误需要重新开具' },
          {value: '3', name: '因为实际经营业务需要，放弃享受减按1%征收率征收增值税政策' },
        ],
        reasonList:[{value: '1', name: '销售退回' },
          {value: '2', name: '开票有误' },
          {value: '3', name: '服务中止' },
          {value: '4', name: '销售折让' }
        ],
        categoryTree: [],
        restaurants: [],
        subjectName: 'first',
        newEmployData: {},
        saveConfig: {
          top: '20vh',
          width: '21rem',
          title: '提示',
          center: true,
          btnTxt: ['确定', '取消'],
        },
        storageData :[],
        storageLength : 0,
      }
    },
    methods: {
      checkTax (rule, value, callback) {
        if (value == "") {
          return callback(new Error("请输入纳税人识别号"));
        } else if (
            value.length == 15 ||
            value.length == 16 ||
            value.length == 17 ||
            value.length == 18 ||
            value.length == 19 ||
            value.length == 20
        ) {
          const pattern = /^[A-Z0-9]+$/;
          if (!pattern.test(value)) {
            return callback(new Error("纳税人识别号只能输入数字或大写英文字母"));
          }
          callback();
        } else {
          return callback(new Error("请检查纳税人识别号"));
        }
      },
      checkTax1  (rule, value, callback) {
        if (!value) {
          callback();
        } else if (
            value.length == 15 ||
            value.length == 16 ||
            value.length == 17 ||
            value.length == 18 ||
            value.length == 19 ||
            value.length == 20
        ) {
          const pattern = /^[A-Z0-9]+$/;
          if (!pattern.test(value)) {
            return callback(new Error("纳税人识别号只能输入数字或大写英文字母"));
          }
          callback();
        } else {
          return callback(new Error("请检查纳税人识别号"));
        }
      },
      changeSslkjly(val){
        this.issueStatus = val;
      },
      addDiscount(){
        this.$refs.employRefs.showDiscount()
      },
      submitForm(formName) {  // 表单提交
        if(this.getToken('hasJoinCompany') == 'false'){
          einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
          return false;
        }
        let amountNum=0,taxAmountNum=0,tradeNameNum=0,num=0;
        let status = false;
        this.$refs[formName].validate((valid) => {
          if (valid) {
        
          //校验发票明细数据
            for (let i = 0; i < this.employData.fyxm.length; i++) {
              let item = this.employData.fyxm[i];
              if (item.spmc && item.spmc != '') { tradeNameNum += 1 } 
              if (item.je && item.je != '' && item.je != '0.00' && item.je != '0' ) { amountNum += 1 }
              if (item.se && item.se != ''  ) { taxAmountNum += 1 }
              if (item.sl !== '' && item.sl != undefined) { num += 1 }
            }
            if ((amountNum != 0) && (taxAmountNum != 0) && (tradeNameNum !=0) && (amountNum === taxAmountNum && taxAmountNum === tradeNameNum&& tradeNameNum === num)) {  //校验
              status = true
            }else{
              try {
                this.employData.fyxm.map((item, index) => {  //未取值判断
                  if (!item.spmc ){
                    throw new Error(`亲，发票数据第${index + 1}行，请输入商品名称`)
                  }else if (!item.je || item.je == '' || item.je === '0.00' || item.je === '0') {
                    throw new Error(`亲，发票数据第${index + 1}行，请输入金额且不能为0`)
                  } else if (!item.se || item.se === '') {
                    throw new Error(`亲，发票数据第${index + 1}行，请输入税额`)
                  }else if (item.sl == undefined || item.sl === '') {
                    throw new Error(`亲，发票数据第${index + 1}行，请选择税率`)
                  }
                  // else if ( ( item.spsl != '' && (item.spsl * 1) == 0 ) ) {
                  //   throw new Error(`发票数量不能为空`)
                  // }
                })
              } catch (error) {
                this.$message.error(error.message)
                return
              }
            }
            if (status) {   
              let list = this.employData.fyxm.filter(function (item) {
                return item.je != '' // 过滤出空值 list
              });        
              let filterList = list.map(item=>{
                return{
                  ...item,
                  sl: item.sl * 1 / 100,
                }
              })
              this.newEmployData = { ...this.employData,fyxm:filterList }
              if(this.$route.query.faildRecord){
                this.newEmployData = { ...this.employData,fyxm:filterList,faildRecord:this.$route.query.faildRecord,companyId: this.getToken('companyId'), userId: this.getToken('userId'),}
              }else{
                this.newEmployData = { ...this.employData,fyxm:filterList,}
              }
              this.add('post', '/api/sks/invoice/issue', this.newEmployData)
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      add(method, url, data) {
        this.isdeling = true
        this.isLoading = true
        axios({
          method: method,
          url: url,
          data: data,
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          },
        }).then(res => {
          if (res.data.success) {
              this.message = this.category == '2' ? '红冲成功' :'新增成功！'
              einvAlert.success("提示",this.message)
              setTimeout(() => {
                this.$router.go(-1);
              }, 1500)
          } else {
            this.employData.fpqqlsh =  uuid.v1();  //发票流水号重置
            this.$message.error(res.data.msg)
          }
          this.isLoading = false
          this.isdeling = false
          this.delDialog = false
        }).catch(err => {
          this.$message.error(err)
          this.isLoading = false
          this.isdeling = false
        })
      },
      redFlush(formName){  //红冲弹窗
        if(this.getToken('hasJoinCompany') == 'false'){
          einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
          return false;
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
          let filterList = this.employData.fyxm.map(item=>{
              return{
                ...item,
                sl: item.sl * 1 / 100,
              }
            })
          this.newEmployData = { ...this.employData , 
            companyId: this.$route.query.companyId,
            fpqqlsh: uuid.v1(),
            kplx: this.category=='2'?'1':'0',           // 开票类型 0：正数票开具；1：负数票开具	
            userId:this.getToken('userId'),  //操作人Id	
            yfpdm:this.employData.fpdm,           //原发票代码	
            yfphm:this.employData.fphm,           //原发票号码	
            ykprq:this.employData.kprq,           //原开票日期	
            fyxm:filterList,
          }
           let _this = this;
           //红冲发票
           einvAlert.confirm(function(){
              _this.add('post', '/api/sks/invoice/issue', _this.newEmployData) 
           },"请确认是否要红冲","确定","取消")
          }
        })
      },
      back() {
        this.$router.go(-1)
      },
      querySearchAsync(queryString, cb) {
        if (!queryString) {
          cb([])
          return
        }
        this.$store.dispatch("QueryCustomer", {
          // keyword: queryString
          name: queryString,
          companyId: this.getToken('companyId'),
        }).then(res => {
          if (res.success) {
            this.restaurants = res.data.map((item) => {  //梳理数组结构
              return {
                ...item,
                taxId: item.taxId,
                value: item.name
              };
            });
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              cb(results);
            }, 500 * Math.random());
          } else {
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
         // einvAlert.error("提示",err)
        })
      },
      createStateFilter(queryString) {
        return (state) => {
          return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
        };
      },
      handleSelect(item) {
        console.log(item);
        this.employData.ghdwmc = item.name;
        this.employData.ghdwsbh = item.taxId;
        this.employData.ghdwdzdh = item.addressAndPhone;
        this.employData.ghdwyhzh = item.banknameAndAccount;
        this.employData.email = item.email;
      },
     
      // 编辑信息
      getDetail() {
        this.showLoading = true;
        this.$store.dispatch("QueryInvoiceDetail", { // 发票详情查询
            companyId: this.$route.query.companyId || this.getToken('companyId'),
            fpdm:this.$route.query.fpdm,
            fphm:this.$route.query.fphm,
            kprq:this.$route.query.kprq,
          }).then(res => {
            if (res.success) {
              this.employData = {
                ...res.data,
                fyxm:res.data.fyxm.map((items)=>{
                  return {
                    ...items,
                    sl: items.sl * 100,
                  }
                })
              }
              this.showLoading = false;
            } else {
              einvAlert.error("提示",res.msg)
              this.showLoading = false
            }
          }).catch(err => {
            this.$message.error(err)
          })
      },
      // 重开发票编辑信息
      getRequer() {
        this.showLoading = true;
        this.$store.dispatch("GetReq", { // 发票详情查询
            companyId: this.getToken('companyId'),
            id: this.$route.query.faildRecord,
          }).then(res => {
            if (res.success) {
              this.employData = {
                ...res.data,
                fpqqlsh:uuid.v1(),
                fyxm:res.data.fyxm.map((items)=>{
                  return {
                    ...items,
                    sl: items.sl * 100,
                  }
                })
              }
              this.showLoading = false;
            } else {
              einvAlert.error("提示",res.msg)
              this.showLoading = false
            }
          }).catch(err => {
            this.$message.error(err)
          })
      },
      
      //查询接入企业
      findJoinCompany() {
        this.showLoading = true;
        this.$store.dispatch("FindByIdJoinCompany", { // 查询接入企业
            id: this.getToken('companyId'),
          }).then(res => {
            if (res.success) {
              this.employData.xhdwmc = res.data.companyName; //名称
              this.employData.xhdwsbh  = res.data.taxId;     //纳税人识别号
              var arr = res.data.address.split(',')
              this.employData.xhdwdzdh = CodeToText[arr[0]]+CodeToText[arr[1]]+CodeToText[arr[2]]+' '+res.data.detailAddress+' '+res.data.companyPhone;  //地址、电话
              this.employData.xhdwyhzh = res.data.bankName+' '+res.data.bankAccountNo;//开户行及账号              
              this.showLoading = false;
            } else {
              console.log(res);
              einvAlert.error("提示",res.msg)
              this.showLoading = false
            }
          }).catch(err => {
            this.$message.error(err)
          })
      },
    },
    mounted() {
      console.log(this.category,'开票失败query数据',this.$route.query.category,this.reopen,this.$route.query.reopen)
      if(this.category == '1'){
        if(this.reopen=='1' || this.$route.query.reopen){
          this.getRequer()
        }
        this.findJoinCompany();
      }
      if (this.category == '2') {
        this.getDetail()
      }
    },
    watch: {
      'employData.fyxm': {
        handler(val, oldVal) {
          // if ( val && this.employData.isEnterDetail == '1') { // 选中需要明细是 重新计算数据
          //   this.initArr();  //计算textarea
          // }
        },
        deep: true,
        // immediate: true
      },
    }
  }
</script>

<style lang="less" scoped>
  .app-container {
    // background-color: #fff;
    // padding: 0 20px;
    height: 100%;
    overflow-y: auto;
    .container{
      padding: 0 20px 50px;
      background: #fff;
      min-height: 89%;
      nav{
        margin-bottom: 0 !important;
      }
    }
    .date{
        margin: 0;
        text-indent: 24px;
        line-height: 20px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #606060;
        span{
          margin-right: 20px;
        }
    }
    .title {
      height: 59px;
      line-height: 59px;
      text-align: center;
      font-size: 18px;
    //   font-weight: Bold;
      border-bottom: 1px solid #dedede;
    }
    .employ_form {
        width: 1516px;
        min-height: 770px;
        background: #ffffff;
        border: 1px solid #dedede;
        margin: 10px auto 0; 
        /deep/ .el-form-item{
          margin-left: 1rem;
          position: relative;
          margin-bottom: 0.2rem;
         }
         /deep/ .el-form-item__error{
            display: none;
         }
        /deep/ .el-form-item label:after {
            content: " ";
            display: inline-block;
            width: 100%;
        }
        /deep/ .el-form-item__label {
            text-align: justify;
            height: 2rem;
            overflow: hidden;
        }
        .before::before {
          content: '*';
          color: #F56C6C;
          margin-left: 2px;
          position: absolute;
          top: 10px;
          left: -15px;
        }
        .sslkjly{
          margin-top: 6px;
        }
        /* 这里去除必选字段的*,这个符号会造成一定影响,进行定位在前面加上" * ". */
        /deep/ .el-form-item.is-required .el-form-item__label:before {
            content: none !important;
        }
        
        
        .part {
            display: flex;
            flex-wrap: wrap;
            // .el-form-item {
            //   width: 33.3%;
            // }
            .el-date-editor{
                width: 320px;
            }
            .el-input{
                width: 320px;
            }
            .el-select {
                width: 320px;
            }
            
        }
        .direction{
            display: flex;
            flex-wrap: wrap;
            height: 180px;
            .header{
                display: flex;
                width: 108px;
                font-size: 16px;
                writing-mode: vertical-rl;
                align-items: center;
                justify-content: space-evenly;
                letter-spacing: 4px;
                border-right: 1px solid #dedede;
            }
            .box1{
                width: 680px;
                border-right: 1px solid #dedede;
                padding-top: 10px;
                color: #606060;
                .el-input{
                    width: 460px;
                }
                .el-select {
                    width: 460px;
                }
                .el-autocomplete{
                    width: 460px !important;
                }
            }
            .box4{
              padding:10px 0 0 10px;
              font-size: 0.73rem;
              p{
                  margin: 0 0 7px 0;
              }
               /deep/ .el-form-item{
                  margin-left: 1rem;
                  position: relative;
                  margin-bottom: 0.5rem;
                }
            }
            .box2{
                width: 680px;
                padding-top: 10px;
                .el-input{
                    width: 460px;
                }
            }
            .box3{
                width: 560px;
                padding-top: 10px;
                padding-left: 40px;
                .el-input{
                    width: 500px;
                }
            }
        }
        .direction1{
          height: 114px;
        }
        .label{
          display: flex;
          justify-content: space-between;
        }
        .formItem{
            width: 320px;
        }
        .tips{
            cursor: pointer;
            color: #4F71FF;
            font-size: 14px;
            margin-left: 20px;
        }
    }
    .footer {
        padding-top: 10px;
        border-top: 1px solid #dcdcdc;
    }
  }
  .provisionSub {
    position: initial !important;
    display: block !important;
  }
  .employ_msg {
    /deep/.el-dialog {
      // height: 160px;
      width: 280px !important;
      border-radius: 8px;
    }
  }
  .proportion {
    width: 88% !important;
    /deep/.el-input__inner {
      text-align: right;
    }
  }
  .proportionInput {
    width: 88% !important;
    /deep/.el-input__inner {
      text-align: left;
    }
  }
</style>